import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO title="Página não encontrada" />
    <h1>PÁGINA NÃO ENCONTRADA</h1>
    <p>Verifique a url</p>
    <br/>
    <br/>
    <br/>
  </Layout>
)

export default NotFoundPage
